<script setup>
import {onMounted, ref} from 'vue';

defineProps(['modelValue']);

defineEmits(['update:modelValue']);

const input = ref(null);
const isDisabled = ref(false);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }

    isDisabled.value = input.value.hasAttribute('disabled');
});
</script>

<template>
    <input class="
        bg-gray-100

        border-0

        text-gray-800

        focus:ring
        focus:ring-opacity-50
        focus:ring-orange-400

        rounded
    " :class="{'bg-gray-200': isDisabled}" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input">
</template>
